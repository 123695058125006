import { Pagination, Empty } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo, useEffect } from 'react';
import { api } from '../../utils/api';
import Detail from './detail';
import styles from './index.less';
import Store from './store';

const Announcement = observer(() => {
  const store = useMemo(() => {
    return new Store();
  }, []);

  const { noticeType, showDetail, data, page, pageSize, total, detail, queryNoticeList, onPaginationChange, switchListAndDetail, goToDetail } = store;
  const name = noticeType === 1 ? '公告' : '系统更新';

  useEffect(() => {
    queryNoticeList();
  }, []);

  return (
    <div className={styles.announcement}>
      {showDetail ? (
        <Detail
          detail={detail}
          goToList={() => switchListAndDetail(false)}
          name={name}
        />
      ) : (
        <>
          <header className={styles.header}>
            {name}
          </header>
          <div className={styles.content}>
            {data.length > 0 ? data.map((item) => {
              return (
                <div
                  className={styles.announcementItem}
                  key={item.id}
                  onClick={() => goToDetail(item)}
                >
                  <div className={styles.title}>
                    <span>
                      {item.title}
                    </span>
                    <span className={styles.right}>
                      {item.createdAt}
                      <span className={styles.lookDetail}>
                        查看详情
                        <span className={`${styles.arrowIcon} icon-home_xq`}/>
                      </span>
                    </span>
                  </div>
                  <div className={styles.introduction}>
                    {item.introduction}
                  </div>
                </div>
              );
            }) : (
              <div className={styles.empty}>
                <Empty
                  description="暂无数据哦！"
                  image={`${api.oss}/images/empty.png`}
                />
              </div>
            )}
          </div>
          <div className={styles.footer}>
            <Pagination
              current={page}
              onChange={onPaginationChange}
              pageSize={pageSize}
              showQuickJumper
              showSizeChanger
              showTotal={(totalCount: number) => `共${totalCount}条记录`}
              total={total}
            />
          </div>
        </>
      )}
      
    </div>
  );
});

export default Announcement;
