import type { PaginationData, BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observable, action } from 'mobx';
import { api } from '../../utils';
import type { Notice } from './interface';

export default class Store {
  constructor() {
    const params = window.location.href.split('?')[1];
    const search = new URLSearchParams(params);
    const type = search.get('type');
    const id = search.get('id');
    const platformType = search.get('platformType');
    if (type) {
      this.noticeType = Number(type);
    }
    if (id) {
      this.showDetail = true;
      this.queryNoticeDetail(Number(id));
    }
    if (platformType) {
      this.platformType = Number(platformType);
    }
  }

  @observable public platformType = 1; // 1:erp 2:pos 3:零售商app 4:供应商app 5：网仓 6：scl

  @observable public noticeType = 1;// 1:公告;2:系统更新

  @observable public showDetail = false; // true:列表页 false:详情页

  @observable public page = 1;

  @observable public pageSize = 20;

  @observable public total = 0;

  @observable public data: Notice[] = [];

  @observable public detail: Partial<Notice> = {
    content: '',
    title: '',
    createName: '',
    publicTime: '',
  };

  @action public queryNoticeList = async(): Promise<void> => {
    const res: PaginationData<Notice> = await request({
      url: api.queryNoticeList,
      method: 'POST',
      data: {
        noticeType: this.noticeType,
        page: this.page,
        pageSize: this.pageSize,
        platformType: this.platformType,
      },
    });
    this.total = res.data.totalCount;
    this.data = res.data.list;
  };

  @action public onPaginationChange = (page: number, pageSize: number): void => {
    this.page = page;
    this.pageSize = pageSize;
    this.queryNoticeList();
  };

  @action public switchListAndDetail = (showDetail: boolean): void => {
    this.showDetail = showDetail;
  };

  @action public goToDetail = (notice: Notice): void => {
    this.detail = notice;
    this.switchListAndDetail(true);
    this.addNoticeViews(notice.id);
  };

  @action public queryNoticeDetail = async(id: number): Promise<void> => {
    const res: BaseData<Notice> = await request({ url: `${api.queryNoticeDetail}/${id}` });
    this.detail = res.data;
    this.addNoticeViews(id);
  };

  @action public addNoticeViews = async(noticeId: number): Promise<void> => {
    await request({
      url: api.addNoticeViews,
      method: 'POST',
      data: {
        noticeId,
        noticeType: this.noticeType,
        platformType: this.platformType,
      },
    });
  };
}
