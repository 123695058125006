import { Breadcrumb } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Notice } from './interface';

interface Props {
  name: string;
  detail: Partial<Notice>;
  goToList: () => void;
}

const Store = observer((props: Props) => {
  const { name, detail, goToList } = props;
  const { createName, title, publicTime, content } = detail;

  return (
    <div className={styles.announcement}>
      <header className={styles.breadcrumb}>
        <Breadcrumb>
          <Breadcrumb.Item
            className={styles.prePage}
            onClick={goToList}
          >
            {name}
          </Breadcrumb.Item>
          <Breadcrumb.Item className={styles.currentPage}>
            查看详情
          </Breadcrumb.Item>
        </Breadcrumb>
      </header>
      <div className={styles.detailContent}>
        <header className={styles.title}>
          {title}
        </header>
        <div className={styles.createInfo}>
          <span>
            发布人：
            {createName}
          </span>
          <span className={styles.ml40}>
            发布时间：
            {publicTime}
          </span>
        </div>
        <div
          className={styles.richText}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
});

export default Store;
